
import { defineComponent } from 'vue';
import Footer from '../../components/Frontend/Footer.vue';
import { mdiChevronUp } from '@mdi/js';

export default defineComponent({
  meta: {
    de: {
      title: 'Styria Media Group. One spirit – unlimited ideas.',
      keywords: ['media company', 'styria' , 'medien' , 'graz' , 'styria media group'],
      description: 'Die STYRIA ist einer der führenden Medienkonzerne in Österreich, Kroatien und Slowenien. Orientierung geben, Vertrauen schaffen, Gemeinschaft fördern.',
      image: require('@/assets/img/og/startseite.png'),
      
    },
    en: {
      title: 'Styria Media Group. One spirit – unlimited ideas.',
      keywords: ['medienkonzern', 'styria' , 'medien' , 'graz' , 'styria media group'],
      description: 'STYRIA is one of the leading media groups in Austria, Croatia and Slovenia. Providing orientation, creating trust, promoting community.',
      image: require('@/assets/img/og/startseite.png'),
    },
  },
  components: {

    Footer,

    
  },
  setup() {
    return {
      mdiChevronUp,
    };
  },
});
